<template>
  <div id="deviceDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车记录管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'passagewayList' }"
        >出入口列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li>
          <span class="label">出入口名称：</span>
          <editable-input
            :value="info.channelName"
            :isRead="
              !buttonList.includes('passagewayList-detail-infoEdit_channelName')
            "
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="channelName"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">出入口位置：</span>
          <editable-input
            :value="info.channelAddress"
            :isRead="
              !buttonList.includes(
                'passagewayList-detail-infoEdit_channelAddress'
              )
            "
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="channelAddress"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">出入口类型：</span>
          <editable-select
            :isRead="
              !buttonList.includes('passagewayList-detail-infoEdit_channelType')
            "
            :list="channelType_list"
            :value="info.channelType"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="channelType"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">停车场位置：</span>
          <editable-input :value="info.parkingLotAddress" />
        </li>
        <li>
          <span class="label">停车场：</span>
          <editable-select
            :isRead="
              !buttonList.includes(
                'passagewayList-detail-infoEdit_parkingLotName'
              )
            "
            :list="parkingLotList"
            :value="info.parkingLotId"
            :label="info.parkingLotName"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="parkingLotId"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">启用黑白名单：</span>
          <editable-select
            :isRead="
              !buttonList.includes('passagewayList-detail-infoEdit_whiteEnable')
            "
            :list="whiteEnable_list"
            :value="info.whiteEnable"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="whiteEnable"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">过场道闸：</span>
          <editable-select
            :isRead="
              !buttonList.includes(
                'passagewayList-detail-infoEdit_parkingAisle'
              )
            "
            :list="select_whether"
            :value="info.parkingAisle"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="parkingAisle"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">是否主动注册：</span>
          <editable-select
            :isRead="
              !buttonList.includes(
                'passagewayList-detail-infoEdit_disableIccIssue'
              )
            "
            :list="select_whether"
            :value="info.disableIccIssue"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="disableIccIssue"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">无记录不开闸：</span>
          <editable-select
            :isRead="
              !buttonList.includes(
                'passagewayList-detail-infoEdit_strictRecord'
              )
            "
            :list="select_whether"
            :value="info.strictRecord"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="strictRecord"
            @save="getInfo"
          />
        </li>
        <li v-if="info.strictRecord === '是'">
          <span class="label">二次识别开闸：</span>
          <editable-select
            :isRead="
              !buttonList.includes(
                'passagewayList-detail-infoEdit_secondCheckOpen'
              )
            "
            :list="select_whether"
            :value="info.secondCheckOpen"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="secondCheckOpen"
            @save="getInfo"
          />
        </li>
        <li v-if="info.channelType === '入口'">
          <span class="label">仅租赁车可进：</span>
          <editable-select
            :isRead="
              !buttonList.includes('passagewayList-detail-infoEdit_leaseOnly')
            "
            :list="select_whether"
            :value="info.leaseOnly"
            :params="{ channelId: channelId }"
            url="/channel/edit"
            attr="leaseOnly"
            @save="getInfo"
          />
        </li>
        <li
          v-if="
            buttonList.includes('passagewayList-detail-infoEdit_soundVolume')
          "
        >
          <span class="label">播报音量：</span>
          <div class="custom-module">
            <span>{{ info.soundVolume }}</span>
            <i
              class="iconfont icon-bianji"
              title="编辑"
              @click="soundVolumeChange"
            ></i>
          </div>
        </li>
        <li v-if="buttonList.includes('passagewayList-detail-openBtn')">
          <span class="label">手动开闸：</span>
          <el-button type="primary" @click="opening" plain>开闸</el-button>
        </li>
      </ul>
    </div>
    <div class="tab-table">
      <el-tabs v-model="tabsName">
        <el-tab-pane
          label="出入口记录"
          name="passagewayList-detail-passagewayTab"
          v-if="tabList.includes('passagewayList-detail-passagewayTab')"
        >
          <passageway-record />
        </el-tab-pane>
        <el-tab-pane
          label="故障记录"
          name="passagewayList-detail-malfunctionTab"
          v-if="tabList.includes('passagewayList-detail-malfunctionTab')"
        >
          <malfunction-record />
        </el-tab-pane>
        <el-tab-pane
          label="设备列表"
          name="passagewayList-detail-deviceTab"
          v-if="tabList.includes('passagewayList-detail-deviceTab')"
        >
          <device-list />
        </el-tab-pane>
        <el-tab-pane
          label="设备列表(主动注册)"
          name="passagewayList-detail-deviceRegisterTab"
          v-if="tabList.includes('passagewayList-detail-deviceRegisterTab')"
        >
          <device-register-tab />
        </el-tab-pane>
        <el-tab-pane
          label="黑白名单"
          name="passagewayList-detail-whiteListTab"
          v-if="tabList.includes('passagewayList-detail-whiteListTab')"
        >
          <blackWhiteList />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    editableInput: () => import("@/components/editable-input.vue"),
    editableSelect: () => import("@/components/editable-select.vue"),
    passagewayRecord: () => import("./tabs/passagewayRecord.vue"),
    malfunctionRecord: () => import("./tabs/malfunctionRecord.vue"),
    deviceList: () => import("./tabs/deviceList.vue"),
    deviceRegisterTab: () =>
      import("./tabs/deviceRegisterTab/deviceRegisterTab.vue"),
    blackWhiteList: () => import("./tabs/black-whiteList/black-whiteList.vue"),
  },
  data() {
    return {
      channelId: "",
      channelType_list: [
        {
          value: "出口",
          label: "出口",
        },
        {
          value: "入口",
          label: "入口",
        },
      ],
      whiteEnable_list: [
        {
          value: 2,
          label: "黑名单",
        },
        {
          value: 1,
          label: "白名单",
        },
        {
          value: 0,
          label: "未启用",
        },
      ],
      tabsName: "passagewayList-detail-passagewayTab",
      info: {},
    };
  },
  computed: {
    tabList() {
      let list = this.$store.state.controlAuthority.tabList;
      let tabsName_list = [
        "passagewayList-detail-passagewayTab",
        "passagewayList-detail-malfunctionTab",
        "passagewayList-detail-deviceTab",
        "passagewayList-detail-deviceRegisterTab",
        "passagewayList-detail-whiteListTab",
      ];
      for (let item of tabsName_list) {
        if (list.includes(item)) {
          this.tabsName = item;
          break;
        }
      }
      return list;
    },
    parkingLotList() {
      let list = this.$store.state.select.parkingLotList;
      return list.map((item) => {
        return {
          value: item.parkingLotId,
          label: item.parkingLotName,
        };
      });
    },
  },
  created() {
    this.channelId = this.$route.params.id;
    this.getInfo();
  },
  methods: {
    async soundVolumeChange() {
      this.$prompt("", "修改播报音量", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^((?!0)\d{1,2}|100)$/,
        inputErrorMessage: "请输入0-100的整数",
        inputPlaceholder: "请输入0-100的整数",
      }).then(async ({ value }) => {
        let res = await this.$http.post("/channel/edit", {
          channelId: this.channelId,
          soundVolume: value,
        });
        if (res.code === 0) {
          this.$message.success("编辑成功!");
          this.getInfo();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    opening() {
      this.$msgbox({
        title: "提示",
        message: "此操作将完成线上开闸, 是否继续?",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            try {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "处理中...";
              let res = await this.$http.post("/channel/manual/opening", {
                channelId: this.$route.params.id,
              });
              if (res.code === 0) {
                if (res.data) {
                  this.$message.success("开闸成功");
                } else {
                  this.$message.error("开闸失败");
                }
              } else {
                this.$message.error(res.msg);
              }
            } catch (err) {
              this.$message.error(err);
            } finally {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }
          } else {
            done();
          }
        },
      });
    },
    async getInfo() {
      try {
        let res = await this.$http.get(
          `/channel/detail/${this.$route.params.id}`
        );
        if (res.code === 0) {
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#deviceDetail {
  .tab-table {
    padding: 15px;
  }
}
</style>
